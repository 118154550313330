import { Components, ColorProfiles } from '@powdr/constants';

// Color Profiles
const {
  BASE, PRIMARY, SECONDARY, TERTIARY, QUATERNARY,
} = ColorProfiles;

export const config = {
  [Components.HEADER]: PRIMARY,
  [Components.MAIN_NAV]: BASE,
  [Components.MEGA_NAV]: BASE,
  [Components.MOBILE_NAV]: TERTIARY,
  [Components.ECOMMERCE_BANNER]: TERTIARY,
  [Components.WEATHER_FEED]: TERTIARY,
  [Components.PROMOTIONS_FEED]: PRIMARY,
  [Components.PROMOTIONS]: TERTIARY,
  [Components.ALERT_BAR]: QUATERNARY,
  [Components.STICKY_NAV]: SECONDARY,
  [Components.TERTIARY_NAV]: BASE,
  [Components.LEVELS_MODAL]: PRIMARY,
  [Components.FOOTER]: TERTIARY,
  [Components.FOOTER_NAV]: TERTIARY,
  [Components.PARTNERS]: TERTIARY,
  [Components.COPYRIGHT]: PRIMARY,
  [Components.MODAL]: PRIMARY,
  [Components.TV]: PRIMARY,
  [Components.DOR]: BASE,
  layout: {
    headerHeight: 70,
    headerHeightMobile: 60,
    headerLogoContainerWidth: 260,
    headerFeedContainer: 250,
  },
};
